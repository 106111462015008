<template>
  <div id="chart-wrap" v-bind:style="{width:chartWidth}" class="text-right">
    <apexchart ref="chart" width="102%" height="200mm" type="bar" :options="chartOptions" :series="fromSeries"/>
    <div v-for="(val, key)  in colorList" class="chart-regend ml-2 cart-regend-item " :style="{padding:'0',border:'solid  '+ val}" style="margin-top: -30px;font-size: 2.5mm;font-weight: bold" >
      {{ key }}
    </div>
  </div>
</template>
<script>

import moment from "moment";
import {COMMONS} from '@/views/aprod/js/common'
let colorsMap = {
  //"0.00":"#FFFFFF",
  //           "0.25":"#BE1F05",
  //           "0.50":"#F60003",
  //           "0.75":"#F64E09",
  //           "1.00":"#F9C308",
  //           "1.25":"#FAFF09",
  //           "1.50":"#C3FF28",
  //           "1.75":"#5BFF27",
  //           "2.00":"#2BFF87",
  //           "2.50":"#31C0FF",
  //           "3.00":"#274EC1",
  //           "3.50":"#2701FF",
  //           "4.00":"#8547FF",
  //           "4.50":"#8500FF",
  //           "5.00":"#520053",
  // "9.99":"#FFFFFF",
  "0.25":"#DCDC80",
  "0.50":"#F0ED83",
  "0.75":"#98B54F",
  "1.00":"#66FF33",
  "1.25":"#00FF99",
  "1.50":"#00B050",
  "1.75":"#00B0B0",
  "2.00":"#3366CC",
  "2.50":"#0000FF",
  "3.00":"#9966FF",
  "3.50":"#9900FF",
  "4.00":"#FFC000",
  "4.50":"#FF962A",
  "5.00":"#FF9999",

}
function getChartOptions(dayCount,colorList,xTitle,yTitle,dayFromTo){

  let xaxisTickAmount = dayCount == 1 ? 12 : dayCount;
  if (dayCount >= 30) {
    xaxisTickAmount = 15;
  }

  let from  = moment(dayFromTo.from);
  let to = moment(dayFromTo.to);
  let dateCategories=[];

  while (from.valueOf() < to.valueOf()){
    dateCategories.push(from.format("MM-DD"));
    from = from.add(1, 'days');
  }
  /** default 90 **/
  let mstroke = {
    show: false,
    colors: ["transient"],
    width:0
  };
  let mplotOptions = {
    bar: {
      horizontal: false,
    },
  };
  let mLabels_offsetX = 0;
  let mAxisTicks = {
    show: true,
    borderType: 'solid',
    // color: '#78909C',
    width: 6,
    offsetX: -20,
    offsetY: 0
  };
  let mGrid = {
    show: true,
    borderColor: '#90A4AE',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    row: {
      colors: undefined,
      opacity: 0.5
    },
    column: {
      colors: undefined,
      opacity: 0.5
    },
    padding: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 20
    },
  };

  let mAxisBorder= {
    show: true,
      // color: '#78909C',
      height: 1,
      width: '110%',
      offsetX: -20,
      offsetY: 0
  };

  if(dayCount == 30){
    mstroke = {
      show: true,
      colors: ["transient"],
      width:5
    };
    mplotOptions = {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        barHeight: '100%',
      },
    };
    mLabels_offsetX = 5;

    mAxisTicks = {
      show: true,
      borderType: 'solid',
      // color: '#78909C',
      width: 6,
      offsetX: -14,
      offsetY: 0
    };
    mGrid = {
      show: false,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      row: {
        colors: undefined,
        opacity: 0.5
      },
      column: {
        colors: undefined,
        opacity: 0.5
      },
      padding: {
        top: 0,
        right: 30,
        bottom: 0,
        left: 10
      },
    };
    mAxisBorder= {
      show: true,
      // color: '#78909C',
      height: 1,
      width: '110%',
      offsetX: 0,
      offsetY: 0
    };
  }

  return  {
    chart: {
      type: 'bar',
      height: 200,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },animations: {
        enabled: false,
      }
    },
    markers: {
      size: 0,
    },
    title: {
      text: "酸素流量 単位:リットル/分",
      align: 'center',
      offsetX: 0,
      offsetY: 5,
      floating: true,
      style: {
        fontSize:  '17px',
        fontWeight:  'bold',
        // fontFamily:  undefined,
        color:  '#263238'
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke:mstroke,
    plotOptions: mplotOptions,
    tooltip: {
      enabled: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return (
            '<div class="card-tooltip-container">'+
            '  <div class="card-tooltip-header">'+data.v+'</div>'+
            '      <div class="card-tooltip-body">'+data.os+'から</div>'+
            '      <div class="card-tooltip-body">'+COMMONS.dhm(data.oy)+'</div>'+
            '</div>'
        );
      }
    },
    xaxis: {
      title:{text:xTitle,offsetY:0},
      tickAmount: xaxisTickAmount,
      type:'category',
      tickPlacement: 'on',
      categories:dateCategories,
      labels: {
        // show: true,
        offsetX:mLabels_offsetX,
        offsetY:10,
        rotateAlways: true,
        rotate: 0,
        formatter: function (val) {
          if (dayCount == 1) {
            return moment(val).format('H') + "時";
          } else {
            return moment(val).format("MM/DD");
          }
        }
      },axisTicks: mAxisTicks,
      axisBorder: mAxisBorder,
    },
    grid: mGrid,
    yaxis: {
      title:{text:yTitle,offsetX:8},
      min: 0,
      // max: 86400,//秒
      max: 86400,
      tickAmount: dayCount == 1 ? 12 : 6,//24時間
      labels:{
        show: true,
        formatter: function(val, index) {
          // console.log("formatter=====================",val);
          // let hour = (val / 60 / 60);
          // if(hour % 2 == 1){
          return (val / 60 / 60);
          // return val;
        }
      }
    },
    colors: [function({ value, seriesIndex ,dataPointIndex, w }) {
      // console.log("color function",value,seriesIndex,dataPointIndex,
      // w.globals.initialSeries[seriesIndex].data[dataPointIndex].c,w);
      // console.log(w.globals.initialSeries[seriesIndex].data[dataPointIndex].c)
      // console.log(w.globals.initialSeries[seriesIndex].data[dataPointIndex].c);
      // return w.globals.initialSeries[seriesIndex].data[dataPointIndex].c;
      // return w.globals.initialSeries[seriesIndex].data[dataPointIndex].c;
      //"#009"系の色を返す

      let chartData = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      if(chartData === undefined){
        return "#FFFFFF";
      }
      // console.log(seriesIndex,dataPointIndex,
      //     JSON.stringify(w.globals.initialSeries[seriesIndex].data[dataPointIndex]));
      let colorResult = colorList[w.globals.initialSeries[seriesIndex].data[dataPointIndex].v];
      if(colorResult === undefined){
        return "#FFFFFF";
      }
      return colorResult;
    }],
    legend: {
      show:false,
    },
    fill: {
      opacity: 1
    }
  };
}
//設定流量
export default {
  name: "OxChartsPrint",
  props: {
    dayCount: {
      required: true
    },
    // series: {type: Array, required: false},
    chartWidth: {default: "100%"},
    chartHeight: {default: "200"},
    fromSeries:{type: Array},
    xTitle:{type:String},
    yTitle:{type:String},
    dayFromTo:{
      type:Object,
      "default":() => ({from:"2021-04-27 00:00:00",to:"2021-04-28 00:00:00"})
    },

  },
  data() {
    return {
      colorList:colorsMap,
      chartOptions:getChartOptions(this.dayCount,colorsMap,this.xTitle,this.yTitle,this.dayFromTo),
      height:300
    }
  },

  // methods:{
  //   replaceData(){
  //     this.getData();
  //   },
  // },
  mounted() {
    this.chartOptions.yaxis.labels.show = false;
  },
  computed:{
    chartWidthPX(){
      return this.chartWidth+'px';
    }
  },watch:{
    fromSeries(){
      this.colorList = colorsMap;
      this.chartOptions = getChartOptions(this.dayCount,colorsMap,this.xTitle,this.yTitle,this.dayFromTo);
    }
  }
};
</script>
<style>
.chart-regend{
  position: relative;
  display: inline-block;
  /*margin-right: 0.5em;*/
  /*white-space: nowrap;*/
  /*line-height: 0.7;*/
  /*margin-top: -20px;*/
  /*margin: 0 auto;*/
}
.cart-regend-item{
  /*padding:5px 3px;*/
  /*border:solid 1px rgb(200, 179, 179);*/
  vertical-align: middle;
  text-align: center;
  margin:0;
  /*position: relative;*/
  /*line-height: 15px;*/
}
#chart-wrap {
  /*max-width: 800px;*/
  max-height:100%;
  height:100%;
  position: relative;
  /*margin: 35px auto;*/
}
#chart {
  padding-bottom: 30px;
  /*max-width: 800px;*/
}

/*.legend {*/
/*  !*max-width: 800px;*!*/
/*  !*left: 80px;*!*/
/*  !*bottom: 20px;*!*/
/*  margin-top: -20px;*/
/*  !*border: solid 1px black;*!*/
/*  width:100%;*/
/*  max-height:30px;*/
/*  margin-right: 2%;*/
/*  position: absolute;*/
/*  !*text-align: right;*!*/
/*}*/
.card-tooltip-container{
  border: solid 1px sandybrown;
  width:250px;
  text-align: center;
  border-radius: 5%;
}
.card-tooltip-header{
  background-color: sandybrown;
  text-align: center;
  color: white;
  font-weight: bold;
}
.card-tooltip-body{
  text-align: left;
}
</style>
